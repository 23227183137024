import t from '@jetshop/intl';
import { ErrorMessage, Field, FieldArray } from 'formik';
import get from 'lodash.get';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useQuery } from 'react-apollo';
import { DynamicContentQuery } from '../DynamicContentQuery.gql';
import {
  InputError,
  InputPositive,
  ToggleViewPasswordButton,
} from './InputStatus';

const Input = styled('input')`
  height: 40px;
  background: #fcfcfc;
  border: 1px solid #dedede;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-bottom: 0.25rem;
  padding-right: 40px;
  &::disabled {
    background: #ffffff;
    color: #808080;
  }
`;

export const Wrapper = styled('div')`
  margin-bottom: ${(props) => (props.isPID ? '2.5rem' : '1.5rem')};
  .label-checkbox span {
    background: #ccc !important;
    svg {
      fill: black !important;
      use {
        fill: black !important;
      }
    }
  }
  .checkBoxWrap {
    display: flex;
    .status-wrapper {
      top: 0;
    }
    a {
      color: black;
    }
    input[type='checkbox'] {
      display: none;
      -webkit-appearance: checkbox;
    }

    > div.checkBoxInner {
      user-select: none;
      display: flex;
      &::before {
        border: 1px solid #dedede;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        content: '';
        background: white;
        display: block;
        font-size: 2rem;
        line-height: 30px;
        text-align: center;
      }
      > div {
        width: calc(100% - 40px);
      }
    }
    input[type='checkbox']:checked + div::before {
      content: '✓';
    }
  }
`;

export const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
  max-width: 430px;
`;

export const Label = styled('label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.25rem;
  display: block;
  .req {
    color: hsl(0, 0%, 60%);
    margin-left: 0.5em;
  }
`;

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  validate,
  disableValidation,
  ...props
}) => {
  const [currentType, setType] = useState(type);
  const isPID = name === 'pid';
  return (
    <Wrapper className={props.wrapperClassName} isPID={isPID}>
      <Label disabled={disabled} htmlFor={name}>
        <span>{label || name}</span>
        {required && <span className="req">{t('(Required)')}</span>}
      </Label>
      <Field
        id={name}
        name={name}
        validate={validate}
        render={({ field, form: { touched, errors } }) => (
          <div>
            <Input
              {...props}
              {...field}
              disabled={disabled}
              type={currentType}
            />
            {type === 'password' && (
              <ToggleViewPasswordButton
                className={currentType === 'password' ? 'hidden' : 'visible'}
                onClick={() => {
                  currentType === 'password'
                    ? setType('text')
                    : setType('password');
                }}
              />
            )}
            {!disableValidation && (
              <>
                {get(touched, field.name) && get(errors, field.name) && (
                  <InputError />
                )}
              </>
            )}
          </div>
        )}
      />
      {!disableValidation && <ErrorMessage name={name} component={ErrorSpan} />}
    </Wrapper>
  );
};

const ConsentBox = ({
  label,
  disabled,
  success,
  warning,
  name,
  required,
  disableValidation,
  ...props
}) => {
  const { data, loading } = useQuery(DynamicContentQuery, {
    variables: { id: 1966 },
  });
  const [consent, setConsent] = useState(false);

  const validate = (value) => {
    let errorMessage;
    if (value === false) {
      errorMessage = t('You need to accept the terms');
    }
    return errorMessage;
  };

  if (loading) return null;

  return (
    <Field
      id="consents.xL0"
      name="consents.xL0"
      defaultValue={false}
      checked={consent}
      validate={validate}
      render={({ field, form: { touched, errors, setFieldValue } }) => (
        <Wrapper
          className={props.wrapperClassName}
          onClick={() => {
            setFieldValue('consents.xL0', !field.value);
          }}
        >
          <Label disabled={disabled} htmlFor={name}>
            <span>{label || name}</span>
            {required && <span className="req">{t('(Required)')}</span>}
          </Label>
          <div className="checkBoxWrap">
            <input
              {...props}
              {...field}
              disabled={disabled}
              type="checkbox"
              checked={field.value}
            />
            <div className="checkBoxInner">
              <div
                dangerouslySetInnerHTML={{ __html: data.category.content }}
              />
            </div>
            {!disableValidation && (
              <>
                {get(touched, field.name) && get(errors, field.name) && (
                  <InputError />
                )}
                {get(touched, field.name) && !get(errors, field.name) && (
                  <InputPositive />
                )}
              </>
            )}
          </div>
        </Wrapper>
      )}
    />
  );
};

export { ConsentBox };
export default InputWithLabel;
