import React from 'react';
import { useEffect } from 'react';
import { styled } from 'linaria/react';
import { useQuery,useLazyQuery } from 'react-apollo';

import { ProductCard } from '../../CategoryPage/ProductCard';
import ProductCarousel from '../../ui/ProductCarousel';
import BestSellerQuery from './BestSellerQuery.gql';
import { Inner } from '../../CategoryPage/CategoryHeader';
import Spinner from '../../ui/Spinner';

const ProductGridWrapper = styled('div')`
  max-width: 94rem;
  width: 100%;
  margin: auto;
  padding: 1rem 2rem;

  ${Inner} & {
    padding: 1rem 0rem;
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 0.35rem;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
  }
`;

const BestSellers = ({ minPrice, maxPrice, limit, category }) => {  
  const { loading, error, data } = useQuery(BestSellerQuery, {
    variables: { path: category?.value?.primaryRoute?.path,limit:limit?.value },
    skip: !category?.value?.primaryRoute?.path,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  // changing the fetch policy to no-cache means that it wont cache the data to store
  // so the previous conflict with the query in the category page is avoided

  if (loading) return <Spinner/>;
  if (error) return null;
  if (!data) return <div>No data available.</div>;

  let filteredProducts = data.route.object.products.result;

  if (minPrice?.value) {
    filteredProducts = filteredProducts.filter(
      product => product.price.exVat > parseInt(minPrice.value)
    );
  }

  if (maxPrice?.value) {
    filteredProducts = filteredProducts.filter(
      product => product.price.exVat < parseInt(maxPrice.value)
    );
  }

  filteredProducts = filteredProducts.slice(0, parseInt(limit.value));

  return (
    <ProductGridWrapper>
      <ProductCarousel
        products={filteredProducts}
        listName={data.route.object.name}
        categoryPath={data.route.object.primaryRoute}
        loading={loading}
        ProductComponent={ProductCard}
        toShow={filteredProducts.length < 5 ? filteredProducts.length : 5}
      />
    </ProductGridWrapper>
  );
};

export default BestSellers;
