import React from 'react';

import { styled } from "linaria/react";
import { theme } from "../../Theme";

import CampaignPushQuery from './CampaignPush.gql';

import { useQuery } from 'react-apollo';

const StyledPush = styled('div')`
  background: ${theme.colors.accent};
  color: #fff;
  text-align: center;
  padding: 1.1rem 0.8rem;
  font-size: 13px;
  span {
    font-size: 28px;
    font-weight: 700;
  }
  a {
    color: #fff;
    text-decoration: none;
  }

  ${theme.below.md} {
    font-size: 10px;
    padding: 0.7rem 0.3rem;
    span {
      font-size: 18px;
      line-height: 27px;
      display: block;
      padding-bottom: 3px;
    }
    p:last-of-type {
      font-size: 10px;
    }
  }
`;

const CampaignPush = () => {
  const { data } = useQuery(CampaignPushQuery, {
    variables: {
      id: 1963,
    },
  });
  const replaceH2 = new RegExp('h2', 'g'); // To avoid semantic error in SEO purpose
  if (!data) return null;
  return (
    <StyledPush
      dangerouslySetInnerHTML={{
        __html: data.category.content.replace(replaceH2, 'span'),
      }}
    />
  );
};
export default CampaignPush;
